<template>
  <div class="pad30">
    <div class="mb30">
      <div class="mb30">
        <b-button pill :variant="customPrimaryVariant" class="fw500" :to="{ name: 'rdDashboard' }">
          <i class="fas fa-arrow-left mr5"></i>
          {{ $t("Return") }}
        </b-button>

        <h5 class="text-left titleDetails">
          <span style="font-size: 70%">{{ $t("Abondement") }}</span>
          <i class="fas fa-angle-double-right ml5 mr5"></i>
          {{ marketing }}
        </h5>
      </div>

      <div class="row">
        <div class="col-sm-12">
          <div class="cardInfo">
            <div class="text-left">
              <p>
                <i class="fas fa-map-marker-alt mr5"></i>
                {{ $t("CodedPOS") }}
              </p>
              <p class="m0">
                <b>{{ pointCode }}</b>
              </p>
            </div>
          </div>

          <div class="cardInfo">
            <div class="text-left">
              <p>
                <i class="fas fa-map-marker-alt mr5"></i>
                {{ $t("NamePOS") }}
              </p>
              <p class="m0">
                <b>{{ pointText }}</b>
              </p>
            </div>
          </div>

          <div class="cardInfo">
            <div class="text-left">
              <p>
                <i class="far fa-calendar-alt mr5"></i>
                {{ $t("LimitDate") }}
              </p>
              <p class="m0 warningColor">
                <b>{{ deadline | dateGenericFormat }}</b>
              </p>
            </div>
          </div>

          <div class="cardInfo" v-if="allowRate === true">
            <div class="text-left">
              <p>
                <i class="fas fa-tag mr5"></i>
                {{ $t("Threshold") }}
              </p>
              <p class="m0">
                <b>{{ threshold | convertInEuro }}</b>
              </p>
            </div>
          </div>
          
          <div class="actionDiv float-right">
            <b-button 
              v-if="[7,8,9].includes(status)"
              pill :variant="customPrimaryVariant" 
              class="fw500 float-right" 
              @click="makePlandoneEditableAgain"
            >
                <i class="far fa-edit mr5"></i>
                {{ $t('Edit') }}
            </b-button>
            <b-button pill :variant="customPrimaryVariant" class="fw500 ml15 float-right" @click="downloadExcel">
              <i class="far fa-file-excel mr5"></i>
              {{ $t("DownloadFile") }}
            </b-button>
            <b-button pill :variant="customPrimaryVariant" class="fw500 ml15 float-right" v-b-modal.refuseOperation
              v-show="(status === 1 || status === 4) && isRefused === 1">
              <i class="fas fa-times mr5"></i>
              {{ $t("return") }}
            </b-button>
            <b-button pill :variant="customPrimaryVariant" class="fw500 ml15 float-right" v-b-modal.validateOperation
              v-show="(status === 1 || status === 4) && isRefused === 0">
              <i class="fas fa-check mr5"></i>
              {{ $t("Validate") }}
            </b-button>
            <b-button 
              v-if="![7,8,9].includes(status)"
              pill :variant="customPrimaryOutlineVariant" 
              class="fw500 ml15 float-right"
              v-b-modal.finishOperation
            >
              {{ $t("finish") }}
            </b-button>
          </div>
        </div>
      </div>

      <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>

      <!-- Validate -->
      <b-modal no-close-on-backdrop no-close-on-esc id="validateOperation" centered
        :title="$t('TitleValidateOperation')" ref="validateOperationModal">
        <p>{{ $t("ValidateOperation2") }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="changePlanStatus(7)">
              {{ $t("Validate") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('validateOperationModal')">
              {{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Validate -->
      <b-modal no-close-on-backdrop no-close-on-esc id="finishOperation" centered :title="$t('warning')"
        ref="finishOperationModal">
        <p>{{ $t("finishText") }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="changePlanStatus(7)">
              {{ $t("confirm") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('finishOperationModal')">
              {{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Refuse -->
      <b-modal no-close-on-backdrop no-close-on-esc id="refuseOperation" centered :title="$t('TitleRefuseOperation')"
        ref="refuseOperationModal">
        <p>{{ $t("refuseOperation") }}</p>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="changePlanStatus(3)">
              {{ $t("confirm") }}</b-button>
            <b-button class="float-right mr5" variant="secondary" pill @click="hideModal('refuseOperationModal')">
              {{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Add comment -->
      <b-modal no-close-on-backdrop no-close-on-esc id="refuseCommentModal" centered :title="$t('addComment')"
        ref="refuseCommentModal">
        <b-form-textarea id="commentTextarea" v-model="commentTextarea" :placeholder="$t('comment')" rows="3">
        </b-form-textarea>

        <template v-slot:modal-footer>
          <div class="w-100">
            <b-form-group>
              <b-form-radio v-model="noCommentRadio" name="noCommentRadio" value="1" id="noCommentRadio">
                {{ $t("noComment") }}</b-form-radio>
            </b-form-group>
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="setRefuse">{{ $t("Validate") }}
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Edit comment -->
      <b-modal no-close-on-backdrop no-close-on-esc id="editCommentModal" centered :title="editCommentTitle"
        ref="editCommentModal" :hide-footer="status === 3">
        <p v-if="editCommentMode === 2" class="mb15">
          <b>{{ $t("doDeleteComment") }}</b>
        </p>
        <b-form-textarea id="editCommentTextarea" v-model="editCommentTextarea" :placeholder="$t('comment')" rows="3"
          :disabled="isTextareaDisabled"></b-form-textarea>

        <template v-slot:modal-footer>
          <div class="w-100">
            <!-- Mode 0 -->
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="changeCommentMode(2)"
              v-if="editCommentMode === 0"><i class="fas fa-trash mr5" />{{ $t("Delete") }}</b-button>
            <b-button class="float-right mr5" :variant="customPrimaryVariant" pill @click="changeCommentMode(1)"
              v-if="editCommentMode === 0"><i class="fas fa-edit mr5" />{{ $t("Edit") }}</b-button>

            <!-- Mode 1 -->
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="modifyComment"
              v-if="editCommentMode === 1">{{ $t("Validate") }}</b-button>
            <b-button class="float-right mr5" pill @click="changeCommentMode(0)" v-if="editCommentMode === 1">
              {{ $t("Cancel") }}</b-button>

            <!-- Mode 2 -->
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="deleteComment"
              v-if="editCommentMode === 2">{{ $t("Delete") }}</b-button>
            <b-button class="float-right mr5" pill @click="changeCommentMode(0)" v-if="editCommentMode === 2">
              {{ $t("Cancel") }}</b-button>
          </div>
        </template>
      </b-modal>

      <!-- Edit abundance -->
      <b-modal no-close-on-backdrop no-close-on-esc id="editAbundanceModal" centered :title="$t('RateInput')"
        ref="editAbundanceModal">
        <b-form-group>
          <b-form-radio class="i-block mr15" v-model="percentageAmountEdit" name="percentageAmountEdit"
            value="percentage">{{ $t("percentage") }}</b-form-radio>
          <b-form-radio class="i-block mr15" v-model="percentageAmountEdit" name="percentageAmountEdit" value="amount">
            {{ $t("amount") }}</b-form-radio>
          <b-form-radio class="i-block" v-model="percentageAmountEdit" name="percentageAmountEdit" value="turnoverIndicator">
            {{ $t("QuotaVNLabel") }}</b-form-radio>
        </b-form-group>

        <div v-if="percentageAmountEdit === 'percentage' || percentageAmountEdit === 'amount'">
          <label class="fw-500">{{ $t("abundanceAmount") }}</label>
          <b-form-input v-mask="inputMask" v-model="abundanceAmount" placeholder="0" @keypress="isNumber($event)"></b-form-input>
        </div>
        <div v-else style="position: relative;">
          <b-form-input type="number" v-model="abundanceQuotaVNPercentage" @keypress="isNumber($event)" class="mt10"
            style="width:45%; display:inline!important;">
          </b-form-input><div class="placeholder_percent">%</div>
          <b-form-input type="number" v-model="abundanceQuotaVNAmount" class="mt10"
            style="margin-left: 20px; width:45%; display:inline!important;">
          </b-form-input><div class="placeholder_amount">€</div>
        </div>



        <template v-slot:modal-footer>
          <div class="w-100">
            <b-button class="float-right" :variant="customPrimaryVariant" pill @click="setAbundanceAmount">
              {{ addModifyText }}</b-button>
            <b-button class="float-right mr5" pill @click="hideModal('editAbundanceModal')">{{ $t("Cancel") }}
            </b-button>
          </div>
        </template>
      </b-modal>

      <!-- Tables -->
      <template>
        <div v-for="item in groupTrades" :key="item.trade_code">
          <h5 class="mt30 mb15 text-left">
            <i class="fas fa-list mr5"></i>
            {{ item.title }}
          </h5>

          <b-table responsive bordered class="m0" :fields="fields" :items="item.array" :tbody-tr-class="rowClass"
            id="drDetailsTable">

            <template v-slot:cell(operation_name)="row">
              {{ row.item.operation_name }}
              <i v-if="(row.item.customCeil) || (row.item.turnoverContribution  && row.item.customCeil)" class="fas fa-tag ml5 primaryColor cursor-pointer"
                v-b-popover.hover.right="row.item.contribLabel" :title="$t('customCeil')"></i>
            </template>

            <template v-slot:cell(actions)="row">
              <form v-show="[0,1,3,4,8].includes(status)">
                <div v-show="[0,1,4,8].includes(status)">
                  <div @click.stop="notTreated(row.item, row.index)" class="pointer">
                    <input type="radio" :checked="row.item.status == 0" :name="'radio' + row.item.uniqueId"
                      :id="'radio0' + row.item.uniqueId" class="i-block" />
                    <label class="ml5 i-block" :for="'radio0' + row.item.uniqueId">
                      non traité
                    </label>
                  </div>

                  <div @click.stop="accept(row.item, row.index)" class="pointer">
                    <input type="radio" v-show="[0,1,4,8].includes(status)" :checked="row.item.status == 1"
                      :name="'radio' + row.item.uniqueId" :id="'radio1' + row.item.uniqueId" class="i-block" />
                    <label class="ml5 i-block" :for="'radio1' + row.item.uniqueId">accepter</label>
                  </div>

                  <div @click.stop="refuse(row.item, row.index)" class="pointer">
                    <input type="radio" v-show="[0,1,4,8].includes(status)"
                      :checked="row.item.status === 2 || row.item.status === 3" :name="'radio' + row.item.uniqueId"
                      :id="'radio2' + row.item.uniqueId" class="i-block" />
                    <label class="ml5 i-block" :for="'radio2' + row.item.uniqueId">refuser</label>
                  </div>
                </div>

                <a href="#" @click="editCommentModal(row.item)" v-if="
                    row.item.details.refuse_comment && row.item.status === 3
                  ">1 {{ $t("comment") | lowercase }}</a>
              </form>
            </template>

            <template v-slot:cell(invoice)="row">
              <div style="margin-bottom: 10px" v-for="(item, index) in row.item.invoice" v-bind:key="index">
                <button class="pdfButton" v-on:click="downloadInvoice(item.document_done_code)">
                  <i class="fas fa-file-pdf mr5 dInline" />
                  {{ item.namefile | truncate(10, "...") }}
                </button>
              </div>
            </template>

            <template v-slot:cell(start_date)="row">{{
              row.item.start_date | dateGenericFormat
            }}</template>

            <template v-slot:cell(end_date)="row">{{
              row.item.end_date | dateGenericFormat
            }}</template>

            <template v-slot:cell(drAmount)="row">
              <button v-if="![0,3,7,8,9].includes(status)" class="whiteButton" v-on:click="
                  editAbundance(
                    row.item.operation_done_code,
                    row.item.drAmount,
                    row.item
                  )
                ">
                {{ row.item.drAmount }}
                <i class="fas fa-edit ml5" />
              </button>
            </template>

            <template v-slot:cell(amountFromDr)="row">
              <span v-if="row.item.customCeil">
                {{ row.item.drCustomCeil | convertInEuro }}
              </span>
              <span v-else>{{ row.item.amountFromDr | convertInEuro }}</span>
            </template>

            <template v-slot:cell(proof)="row">
              <div style="margin-bottom: 10px" v-for="(item, index) in row.item.proof" v-bind:key="index">
                <button class="pdfButton" v-on:click="downloadProof(item.document_done_code)">
                  <i class="fas fa-file-pdf mr5 dInline" />
                  {{ item.namefile | truncate(10, "...") }}
                </button>
              </div>
            </template>
          </b-table>
          <div class="mb75"></div>
        </div>
      </template>

      <div>
        <hr class="mt30" />
        <b-table responsive bordered class="mt30" :fields="summaryFieldsToDisplay" :items="summaryItems" id="summaryTable">
          <template v-slot:cell(totalExpenses)="data">
            <span :class="{ green: isTreated.includes(1) }">{{
              data.item.totalExpenses | convertInEuro
            }}</span>
          </template>
        </b-table>
        <!--
        <b-table responsive bordered class="mt30" :fields="summaryNoneFields" :items="summaryNoneItems"
          id="summaryNoneTable" style="width: 260px">
          <template v-slot:cell(totalNoContributionExpenses)="data">
            <span :class="{ red: isTreated.includes(2) }">{{
              data.item.totalNoContributionExpenses | convertInEuro
            }}</span>
          </template>
        </b-table>
        -->
      </div>
    </div>
  </div>
</template>

<script>
  import http from "../helpers/http";
  import common from "../helpers/common";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  import FileSaver from "file-saver";
  import mixins from "../helpers/mixins.js";

  export default {
    mixins: [mixins],
    beforeMount() {
      common.isAuth(this, http);
    },
    data() {
      const allowRate = this.$cookie.get("allowRate") == "true";
      const brand = this.$cookie.get("brand");

      const headerRate = []; // add header if you need
      headerRate.push({
        key: "drAmount",
        label: this.$i18n.t("tableAbundance"),
        tdClass: "text-right",
      }, {
        key: "amountFromDr",
        label: this.$i18n.t("RateInput"),
        formatter: this.$store.state.setCurrency,
        tdClass: "text-right",
        thStyle: {
          width: "140px",
        },
      });

      return {
        allowRate,
        generalOperations: [],
        isRefused: 0,
        addModifyText: this.$i18n.t("Add"),
        inputMask: "###",
        quotaVNvalue: "",
        abundanceAmount: "",
        abundanceQuotaVNAmount: 0,
        abundanceQuotaVNPercentage: null,
        percentageAmountEdit: "percentage",
        isTextareaDisabled: true,
        editCommentTitle: this.$i18n.t("comment"),
        editCommentTextarea: "",
        commentTextarea: "",
        noCommentRadio: "1",
        currentItem: null,
        threshold: 0,
        isTreated: [],
        brand: window.location.pathname.split("/")[1],
        customPrimaryVariant: window.location.pathname.split("/")[1] + "-customPrimary",
        customSuccessVariant: window.location.pathname.split("/")[1] + "-customSuccess",
        customDangerVariant: window.location.pathname.split("/")[1] + "-customDanger",
        customPrimaryOutlineVariant: "outline-" + window.location.pathname.split("/")[1] + "-customPrimary",
        marketing: "",
        deadline: "",
        planCode: "",
        pointCode: "",
        pointText: "",
        status: 2,
        radio: [],
        radio0: [],
        radio1: [],
        radio2: [],
        isLoading: false,
        fullPage: true,
        planDoneCode: this.$route.params.id,
        fields: [{
            key: "operation_name",
            label: this.$i18n.t("tableOperation"),
          },
          {
            key: "support",
            label: this.$i18n.t("tableSupport"),
            formatter: "joinArray",
          },
          {
            key: "title",
            label: this.$i18n.t("tableTitle"),
          },
          {
            key: "model",
            label: this.$i18n.t("tableModel"),
            formatter: "joinArray",
            thStyle: {
              width: "180px",
            },
          },
          {
            key: "start_date",
            label: this.$i18n.t("tableDateBegin"),
            thStyle: {
              width: "100px",
            },
          },
          {
            key: "end_date",
            label: this.$i18n.t("tableDateEnd"),
            thStyle: {
              width: "100px",
            },
          },
          {
            key: "amount",
            label: this.$i18n.t("tableAmount"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-right",
            thStyle: {
              width: "100px",
            },
          },
          ...headerRate,
          {
            key: "invoice",
            label: this.$i18n.t("tableInvoice"),
          },
          {
            key: "proof",
            label: this.$i18n.t("tableProof"),
          },
          {
            key: "comment",
            label: this.$i18n.t("tableComment"),
          },
          {
            key: "actions",
            label: this.$i18n.t("tableActions"),
            thStyle: {
              width: "170px",
            },
          },
        ],
        /*summaryFields: [{
            key: "totalExpenses",
            label: this.$i18n.t("totalExpenses"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-center",
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "rate",
            label: this.$i18n.t("RateInput"),
            formatter: this.$store.state.setPercentage,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalRate",
            label: this.$i18n.t("TotalRate"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "customCeil",
            label: this.$i18n.t("specificWithoutCeil"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "threshold",
            label: this.$i18n.t("ThresholdConcessionsPlate"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalAllExpenses",
            label: this.$i18n.t("totalAllExpenses"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
        ],
        */
        summaryFields: [{
            key: "totalExpenses",
            label: this.$i18n.t("totalExpenses"),
            formatter: this.$store.state.setCurrency,
            tdClass: "text-center",
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalContribution",
            label: this.$i18n.t("totalContribution"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalCustomCeil",
            label: this.$i18n.t("totalCustomCeil"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
          {
            key: "totalToPay",
            label: this.$i18n.t("totalToPay"),
            formatter: this.$store.state.setCurrency,
            thStyle: {
              width: "1px",
            },
          },
        ],
        /*
        summaryNoneFields: [{
          key: "totalNoContributionExpenses",
          label: this.$i18n.t("totalNoneExpenses_" + brand),
          formatter: this.$store.state.setCurrency,
          tdClass: "text-center",
          thStyle: {
            width: "1px",
          },
        }, ],
        */
        summaryItems: [],
        summaryNoneItems: [],

        editCommentMode: 0,
        currentOperationDoneCode: "",
        trades2: [
          // must be remove
          {
            trade_code: "VN",
            title: this.$i18n.t("NewVehicles"),
            array: this.itemsVN,
          },
          {
            trade_code: "VO",
            title: this.$i18n.t("UsedVehicles"),
            array: this.itemsVO,
          },
          {
            trade_code: "VO",
            title: this.$i18n.t("UsedVehicles"),
            array: this.itemsVO,
          },
          {
            trade_code: "B2B",
            title: this.$i18n.t("BtoB"),
            array: this.itemsB2B,
          },
          {
            trade_code: "APV",
            title: this.$i18n.t("AfterSale"),
            array: this.itemsAPV,
          },
        ],
      };
    },
    watch: {
      abundanceQuotaVNPercentage(val) {
        if (this.percentageAmountEdit === "turnoverIndicator" && val > 100) {
          this.abundanceQuotaVNPercentage = 100;
        }
      },
      abundanceAmount(val) {
        if (this.percentageAmountEdit === "percentage" && val > 100) {
          this.abundanceAmount = 100;
        }
      },
      percentageAmountEdit(val) {
        if (val === "percentage") {
          this.inputMask = "###";
          this.abundanceAmount = Number(this.abundanceAmount);

          if (this.abundanceAmount > 100) {
            this.abundanceAmount = 100;
          }
        } else {
          this.inputMask = "##########";
        }
      },
      commentTextarea(val) {
        if (val) {
          this.noCommentRadio = "0";
        } else {
          this.noCommentRadio = "1";
        }
      },
      noCommentRadio(val) {
        if (val && val === "1") {
          this.commentTextarea = "";
        }
      },
    },
    components: {
      Loading,
    },
    computed: {
      groupTrades() {
        const sectionTrade = this.myTradesList.map((el) => ({
          trade_code: el.trade_code,
          title: el.label,
          array: [],
        }));

        this.generalOperations.forEach((element) => {
          const temp = sectionTrade.find(
            (el) => el.trade_code === element.trade_code
          );
          temp.array.push(element);
        });
        return sectionTrade;
      },

      myTradesList() {
        return this.$store.getters.getTradesFull;
      },

      summaryFieldsToDisplay () {
        return this.allowRate ? this.summaryFields : this.summaryFields.slice(0,1);
      }

    },
    mounted() {
      this.$store.dispatch("getTrade", this.$cookie);
      this.initTable(this);
    },
    methods: {
      isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
      makePlandoneEditableAgain () {
        // switch status to 4 (if actual status is 7 or more)

        this.isLoading = true;
        this.$store.dispatch('updatePlandone', {
          data: { status: 4 },
          $cookie: this.$cookie,
          plandoneCode: this.$route.params.id,
        }).then((res) => {
          this.initTable(this);
        }).catch((e) => {
          console.log(e);
        }).finally(() => {
          self.isLoading = false;
        })
      },
      editAbundance(code, drAmount = null, item = null) {
        this.$refs["editAbundanceModal"].show();
        this.currentOperationDoneCode = code;
        this.currentItem = item;
        if (item.specificContribution && item.specificContribution.rate) {
          this.percentageAmountEdit = "percentage";
          this.abundanceAmount = item.specificContribution.rate;
        } else if (item.specificContribution && item.specificContribution.turnoverContribution) {
          this.percentageAmountEdit = "turnoverIndicator";
          this.abundanceQuotaVNAmount = (item.specificContribution.turnoverContribution.amount/100);
          this.abundanceQuotaVNPercentage = item.specificContribution.turnoverContribution.rateLimit;
        } else if (
          item.specificContribution &&
          item.specificContribution.budgetAmount
        ) {
          this.percentageAmountEdit = "amount";
          this.abundanceAmount = item.specificContribution.budgetAmount;
        }
  
        if (drAmount) {
          this.addModifyText = this.$i18n.t("Validate");
        } else {
          this.addModifyText = this.$i18n.t("modify");
        }
      },
      changeCommentMode(mode) {
        this.editCommentMode = mode;
        if (mode === 1) {
          this.isTextareaDisabled = false;
        } else {
          this.isTextareaDisabled = true;
        }

        switch (mode) {
          case 0:
            this.editCommentTitle = this.$i18n.t("comment");
            break;
          case 1:
            this.editCommentTitle = this.$i18n.t("modifyComment");
            break;
          case 2:
            this.editCommentTitle = this.$i18n.t("warning");
            break;
        }
      },
      modifyComment() {
        this.currentItem.details.refuse_comment = this.editCommentTextarea;
        this.saveRow(this.currentItem, this.editCommentTextarea);
        this.changeCommentMode(0);
        this.hideModal("editCommentModal");
      },
      deleteComment() {
        delete this.currentItem.details.refuse_comment;
        this.saveRow(this.currentItem, "");
        this.changeCommentMode(0);
        this.hideModal("editCommentModal");
      },
      editCommentModal(item) {
        this.$refs["editCommentModal"].show();
        this.currentItem = item;
        this.editCommentTextarea = item.details.refuse_comment;
      },
      resetComment() {
        this.commentTextarea = "";
      },
      initTable(self, loading = true) {
        self.isLoading = loading;

        self.isTreated = [];
        // Get dashboard rows
        http
          .get(
            self.$store.state.apiUrl + "/global/details/" + self.$route.params.id,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then((res) => {
            let response = res[0];
            this.$set(this, 'generalOperations', []);
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            self.threshold = response.data.infos.limit;
            self.marketing = response.data.infos.plan.label;
            self.deadline = response.data.infos.plan.deadline;
            self.planCode = response.data.infos.plan_code;
            self.status = response.data.infos.status;
            self.pointCode = response.data.infos.point_code;
            self.pointText = response.data.infos.point_name;

            /*self.summaryItems = [{
              type: response.data.summary.type,
              totalExpenses: response.data.summary.totalExpenses,
              totalAllExpenses: response.data.summary.totalAllExpenses,
              customCeil: response.data.summary.customCeil,
              rate: response.data.summary.rate,
              totalRate: response.data.summary.totalRate,
              threshold: self.threshold,
            }, ];*/
            self.summaryItems = [{
              type: response.data.summary.type,
              totalExpenses: response.data.summary.totalExpenses,
              totalContribution: response.data.summary.totalContribution,
              totalCustomCeil:response.data.summary.totalCustomCeil,
              totalToPay:response.data.summary.totalToPay,
            },];
            /*
            self.summaryNoneItems = [{
              type: response.data.summary.type,
              totalNoContributionExpenses: response.data.summary.totalNoContributionExpenses,
            }, ];
            */
            for (let i = 0; i < response.data.tableData.trades.length; i++) {
              let resDataI = response.data.tableData.trades[i];
              let tradeCode = resDataI.trade_code;

              for (let l = 0; l < resDataI.data.length; l++) {
                let models = [];
                let modelsCode = [];

                for (let j = 0; j < resDataI.data[l].model.length; j++) {
                  models.push(resDataI.data[l].model[j].model);
                  modelsCode.push(resDataI.data[l].model[j].model_code);
                }

                let comment = "";

                if (resDataI.data[l].comment) {
                  comment = resDataI.data[l].comment;
                }

                let drAmount = "";
                let specificContribution = "";

                if (resDataI.data[l].details.specific_contribution) {
                  specificContribution =
                    resDataI.data[l].details.specific_contribution;

                  if (resDataI.data[l].details && resDataI.data[l].details.specific_contribution && resDataI.data[l].details.specific_contribution.rate) {
                    drAmount = resDataI.data[l].details.specific_contribution.rate.toString().concat(" %");
                  }
                  if (
                    resDataI.data[l].details && resDataI.data[l].details.specific_contribution && resDataI.data[l].details.specific_contribution.budgetAmount
                  ) {
                    drAmount = resDataI.data[l].details.specific_contribution.budgetAmount.toString().concat(" €");
                  }
                  if (
                    resDataI.data[l].details && resDataI.data[l].details.specific_contribution && resDataI.data[l].details.specific_contribution.turnoverContribution
                  ) {
                    self.quotaVNvalue = resDataI.data[l].details.specific_contribution.turnoverContribution.value;
                    if(resDataI.data[l].details.specific_contribution.turnoverContribution.rateLimit){
                      drAmount = (resDataI.data[l].details.specific_contribution.turnoverContribution.amount/100) + ' € ' + this.$t("QuotaVNLimite") + resDataI.data[l].details.specific_contribution.turnoverContribution.rateLimit + ' % ' + this.$t("QuotaVNLabel")  ;
                    } else {
                      drAmount = (resDataI.data[l].details.specific_contribution.turnoverContribution.amount/100) + ' € ' + this.$t("QuotaVNLabel")  ;
                    }
                    
                  }
                }

                self.isTreated.push(resDataI.data[l].status);

                let temp = {
                  uniqueId: Math.round(Math.random() * 10000000),
                  trade_code: resDataI.data[l].trade_code,
                  customCeil: resDataI.data[l].customCeil,
                  drCustomCeil: resDataI.data[l].drCustomCeil,
                  operation_done_code: resDataI.data[l].operation_done_code,
                  operation_code: resDataI.data[l].operation_code,
                  operation_name: resDataI.data[l].operation_name,
                  support: resDataI.data[l].support,
                  title: resDataI.data[l].title,
                  model: models,
                  model_code: modelsCode,
                  start_date: resDataI.data[l].start_date,
                  end_date: resDataI.data[l].end_date,
                  amount: resDataI.data[l].amount,
                  rate: resDataI.data[l].rate,
                  drAmount: drAmount,
                  specificContribution: specificContribution,
                  amountFromDr: resDataI.data[l].drAmount,
                  invoice: resDataI.data[l].invoice,
                  proof: resDataI.data[l].proof,
                  status: resDataI.data[l].status,
                  details: resDataI.data[l].details,
                  comment: comment,
                  contributionLimit: "",
                  actions: "",
                };
                this.generalOperations.push(temp);
              }
            }

            self.isRefused = 0;

            for (let i = 0; i < self.groupTrades.length; i++) {
              for (let j = 0; j < self.groupTrades[i].array.length; j++) {
                if (
                  self.groupTrades[i].array[j].status === 2 ||
                  self.groupTrades[i].array[j].status === 3
                ) {
                  self.isRefused = 1;
                }
              }
            }
          })
          .finally(() => {
            self.isLoading = false;
          });
      },
      downloadInvoice(document_done_code) {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getPDF(
            this.$store.state.apiUrl + "/pos/document_done/" + document_done_code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: res[0].headers["content-type"].split("[")[0],
            });

            FileSaver.saveAs(blob, res[0].headers["content-type"].split("[")[1]);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      downloadProof(document_done_code) {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getPDF(
            this.$store.state.apiUrl + "/pos/document_done/" + document_done_code,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: res[0].headers["content-type"].split("[")[0],
            });

            FileSaver.saveAs(blob, res[0].headers["content-type"].split("[")[1]);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      downloadExcel() {
        let proxy = null;

        if (process.env.VUE_APP_NODE_ENV == "localhost") {
          proxy = true;
        }

        this.isLoading = true;
        http
          .getExcel(
            this.$store.state.apiUrl +
            "/global/document_generation/" +
            this.planDoneCode +
            "?plan_code=" +
            this.planCode,
            this.$cookie.get("auth"),
            this.$cookie.get("authExpires")
          )
          .then((res) => {
            if (res[1]) {
              common.refreshToken(this, res[1].data.token, res[1].data.validity);
            }

            const blob = new Blob([res[0].data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            FileSaver.saveAs(blob, Math.random() + ".xlsx");
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      joinArray(array) {
        if (Array.isArray(array)) {
          return array.join(", ");
        } else {
          return array;
        }
      },
      rowClass(item) {
        if (!item) {
          return;
        }
        if (item.status == 0) {
          return;
        } else if (item.status == 1) {
          return "table-customSuccess";
        } else if (item.status == 2 || item.status == 3) {
          return "table-customDanger";
        }
      },
      hideModal(ref) {
        this.$refs[ref].hide();
      },
      setAbundanceAmount() {
        let self = this;
        let data = {};

        if (this.abundanceAmount || (this.abundanceQuotaVNAmount)) {
          if (this.percentageAmountEdit === "percentage") {
            data.specific_contribution = {
              rate: this.abundanceAmount,
            };
          } else if (this.percentageAmountEdit === "amount") {
            data.specific_contribution = {
              budgetAmount: this.abundanceAmount,
            };
          } else {
            data.specific_contribution = {
              turnoverContribution: {"amount": this.abundanceQuotaVNAmount*100, "rateLimit": this.abundanceQuotaVNPercentage},
            };
          }
          self.isLoading = true;

          // Send all
          http
            .post(
              self.$store.state.apiUrl +
              "/rd/details/editamount/" +
              self.currentOperationDoneCode,
              data,
              self.$cookie.get("auth"),
              self.$cookie.get("authExpires")
            )
            .then(function (res) {
              if (res[1]) {
                common.refreshToken(
                  self,
                  res[1].data.token,
                  res[1].data.validity
                );
              }

              self.summaryItems = [{
                ...res[0].data.summary,
              }, ];

              self.summaryNoneItems = [{
                totalNoContributionExpenses: res[0].data.summary.totalNoContributionExpenses,
              }, ];


              if (data.specific_contribution.rate) {
                self.currentItem.drAmount = data.specific_contribution.rate.concat(
                  " %"
                );
                self.currentItem.amountFromDr = self.setAmountFromDr(
                  self.abundanceAmount,
                  "percentage"
                );
                self.currentItem.specificContribution.rate = data.specific_contribution.rate;
              } else if (data.specific_contribution.turnoverContribution) {
                if(data.specific_contribution.turnoverContribution.rateLimit){
                  self.currentItem.drAmount = (data.specific_contribution.turnoverContribution.amount/100) + ' € ' + self.$t("QuotaVNLimite") + data.specific_contribution.turnoverContribution.rateLimit + ' % ' + self.$t("QuotaVNLabel") ; 

                } else {
                  self.currentItem.drAmount = (data.specific_contribution.turnoverContribution.amount/100) + ' € ' + this.$t("QuotaVNLabel")  ;
                }
                self.currentItem.amountFromDr = self.setAmountFromDr(
                  {"amount": data.specific_contribution.turnoverContribution.amount ,"rateLimit": data.specific_contribution.turnoverContribution.rateLimit, "value": self.quotaVNvalue},
                  "turnoverContribution"
                );
                self.currentItem.specificContribution.turnoverContribution.amount = data.specific_contribution.turnoverContribution.amount;
                self.currentItem.specificContribution.turnoverContribution.rateLimit = data.specific_contribution.turnoverContribution.rateLimit;
              } else if (data.specific_contribution.amount) {
                self.currentItem.drAmount = data.specific_contribution.budgetAmount.concat(
                  " €"
                );
                self.currentItem.amountFromDr = self.setAmountFromDr(
                  self.abundanceAmount,
                  "amount"
                );
                self.currentItem.specificContribution.amount = data.specific_contribution.amount;
              }
              
              common.makeToast(
                self,
                self.$i18n.t("Success"),
                self.$i18n.t("abundanceSuccess"),
                self.brand + "-customSuccess",
                "b-toaster-bottom-center",
                true
              );
            })
            .catch(function () {})
            .finally(() => {
              self.hideModal("editAbundanceModal");
              self.isLoading = false;
            });
        } else {
          common.makeToast(
            self,
            self.$i18n.t("warning"),
            self.$i18n.t("ErrorHaveToPickAmount"),
            self.brand + "-customWarning",
            "b-toaster-bottom-center",
            true
          );
        }
      },
      setAmountFromDr(amount, type) {
        let val = 0;

        if (type === "percentage") {
          val = (this.currentItem.amount * amount) / 100;
        } else if (type === "turnoverContribution") {
          if(amount.rateLimit){
            val = ((amount.amount/100)*amount.value > this.currentItem.amount*amount.rateLimit/100) ? (this.currentItem.amount*amount.rateLimit/100).toFixed(2).replace(/[.,]00$/, "") : ((amount.amount/100)*amount.value).toFixed(2).replace(/[.,]00$/, "");
          } else {
            val = ((amount.amount/100)*amount.value).toFixed(2).replace(/[.,]00$/, "");
          }
          
        } else {
          if (Number(this.currentItem.amount) >= Number(amount)) {
            val = amount;
          } else {
            val = this.currentItem.amount;
          }
        }

        return val;
      },
      saveRow(item, comment = null) {
        let data = {
          status: item.status,
        };

        if (comment !== null && (item.status === 2 || item.status === 3)) {
          data.refuse_comment = comment;
          this.commentTextarea = "";
        }

        let self = this;
        this.isLoading = true;
        // Send all
        http
          .post(
            self.$store.state.apiUrl +
            "/rd/details/save/" +
            item.operation_done_code,
            data,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            self.isRefused = 0;

            for (let i = 0; i < self.groupTrades.length; i++) {
              for (let j = 0; j < self.groupTrades[i].array.length; j++) {
                if (
                  self.groupTrades[i].array[j].status === 2 ||
                  self.groupTrades[i].array[j].status === 3
                ) {
                  self.isRefused = 1;
                }
              }
            }

            self.summaryItems = [];
            self.summaryItems = [{
              ...res[0].data.summary,
            }, ];

            self.summaryNoneItems = [{
              totalNoContributionExpenses: res[0].data.summary.totalNoContributionExpenses,
            }, ];
            // self.initTable(self, false);
          })
          .catch(function () {})
          .finally(() => {
            self.isLoading = false;
          });
      },
      accept: function (item) {
        item.status = 1;
        this.currentItem = item;
        this.currentItem.details.refuse_comment = "";
        this.rowClass(item);
        this.saveRow(item);
      },
      refuse: function (item) {
        this.currentItem = item;
        this.$refs["refuseCommentModal"].show();
      },
      setRefuse: function () {
        this.$refs["refuseCommentModal"].hide();
        if (this.commentTextarea !== "") {
          this.currentItem.status = 3;
          this.currentItem.details.refuse_comment = this.commentTextarea;
        } else {
          this.currentItem.status = 2;
          this.currentItem.details.refuse_comment = "";
        }
        this.rowClass(this.currentItem);
        this.saveRow(this.currentItem, this.commentTextarea);
      },
      notTreated: function (item) {
        item.status = 0;
        this.currentItem = item;
        this.currentItem.details.refuse_comment = "";
        this.rowClass(item);
        this.saveRow(item);
      },

      changePlanStatus(status) {
        let self = this;
        let data = {
          status: status,
        };

        self.isLoading = true;
        // Send all
        http
          .post(
            self.$store.state.apiUrl +
            "/global/validatePlanDone/" +
            self.planDoneCode,
            data,
            self.$cookie.get("auth"),
            self.$cookie.get("authExpires")
          )
          .then(function (res) {

            if(res instanceof Error) {
                throw res;
            }
            if (res[1]) {
              common.refreshToken(self, res[1].data.token, res[1].data.validity);
            }

            if (status === 3) {
              common.makeToast(
                self,
                self.$i18n.t("Success"),
                self.$i18n.t("refuseDone"),
                self.brand + "-customSuccess",
                "b-toaster-bottom-center",
                true
              );
              self.$refs["refuseOperationModal"].hide();
            } else {
              common.makeToast(
                self,
                self.$i18n.t("Success"),
                self.$i18n.t("ValidationDone"),
                self.brand + "-customSuccess",
                "b-toaster-bottom-center",
                true
              );
              self.$refs["validateOperationModal"].hide();
            }

            setTimeout(
              function () {
                self.$router.push({
                  name: "rdDashboard",
                });
              }.bind(this),
              1500
            );
          })
          .catch(function () {
            common.makeToast(
              self,
              self.$i18n.t("Error"),
              self.$i18n.t("ValidationFailed"),
              self.brand + "-customDanger",
              "b-toaster-bottom-center",
              true
            );
          })
          .finally(() => {
            self.isLoading = false;
          });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  // Import custom SASS variable overrides
  @import "../assets/custom-vars.scss";



      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }



    .placeholder_percent {
    position: absolute;
    left: 40%;
    top: 7px;
    pointer-events: none;
    opacity: .5;
  }

  .placeholder_amount {
    position: absolute;
    right: 7%;
    top: 7px;
    pointer-events: none;
    opacity: .5;
  }

  .input:placeholder-shown .placeholder_percent {
    /* if real placeholder is shown - hide fake placeholder */
    opacity: 0;
  }

  label {
    margin-bottom: 0px;
    position: relative;
    top: -2px;
  }

  .pointer {
    vertical-align: middle;
    padding-top: 0.5em;
    text-align: left;
  }

  .pointer>input {
    height: 1em;
    width: 20px;
  }

  .pointer:hover {
    cursor: pointer;

    text-decoration: underline #ff3028;
    background-color: rgba(240, 240, 240, 0.5);
    border-radius: 5px;
  }

  .pointer>* {
    cursor: pointer;
  }

  .customTable th {
    padding-right: 15px;
    font-weight: normal;
  }

  .cardInfo {
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    width: auto;
    height: 100px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: $minBorder;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .titleDetails {
    display: inline-flex;
    align-items: center;
    margin: 0;
    margin-left: 15px;
  }

  .actionDiv {
    height: 100px;
    align-items: flex-end;
    display: inline-flex;
  }
</style>